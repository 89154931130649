// Generated by @hypernym/nuxt-gsap
import { defineNuxtPlugin } from '#app';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
const plugin = defineNuxtPlugin(() => {
  if(process.client) {
  gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);
  
  
}
  return {
    provide: {
      gsap,
      ScrollTrigger,
ScrollToPlugin
    }
  }
})
export default plugin;