import revive_payload_client_4sVQNw7RlN from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/profile_wowup_nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/var/www/html/profile_wowup_nuxt3/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/html/profile_wowup_nuxt3/.nuxt/sentry-client-config.mjs";
import gsapPlugin_HlwIIyl85L from "/var/www/html/profile_wowup_nuxt3/.nuxt/gsapPlugin.mjs";
import plugin_client_UYiXMU8ZyN from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/html/profile_wowup_nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/html/profile_wowup_nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import titles_dw2T9lEw4h from "/var/www/html/profile_wowup_nuxt3/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/var/www/html/profile_wowup_nuxt3/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_nuxt3_gGdllsjxuZ from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import api_GFfDXud5Cr from "/var/www/html/profile_wowup_nuxt3/plugins/api.ts";
import auto_animate_uTpJuTGsMC from "/var/www/html/profile_wowup_nuxt3/plugins/auto-animate.ts";
import strapi_lgLLCZrkNA from "/var/www/html/profile_wowup_nuxt3/plugins/strapi.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  gsapPlugin_HlwIIyl85L,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_nuxt3_gGdllsjxuZ,
  api_GFfDXud5Cr,
  auto_animate_uTpJuTGsMC,
  strapi_lgLLCZrkNA
]