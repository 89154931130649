import { default as indexFFfGstKBXNMeta } from "/var/www/html/profile_wowup_nuxt3/pages/article/[category]/[post]/index.vue?macro=true";
import { default as indexH63gD97L4wMeta } from "/var/www/html/profile_wowup_nuxt3/pages/article/[category]/index.vue?macro=true";
import { default as indexTXou3chr6IMeta } from "/var/www/html/profile_wowup_nuxt3/pages/article/index.vue?macro=true";
import { default as indexgjfQ1nCGspMeta } from "/var/www/html/profile_wowup_nuxt3/pages/index.vue?macro=true";
import { default as index8xexFAR3WxMeta } from "/var/www/html/profile_wowup_nuxt3/pages/our-projects/[project]/index.vue?macro=true";
import { default as component_45stubLB9AXdp1nWMeta } from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLB9AXdp1nW } from "/var/www/html/profile_wowup_nuxt3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "article-category-post___vi",
    path: "/vi/article/:category()/:post()",
    meta: indexFFfGstKBXNMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/article/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "article-category-post___en",
    path: "/article/:category()/:post()",
    meta: indexFFfGstKBXNMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/article/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "article-category___vi",
    path: "/vi/article/:category()",
    meta: indexH63gD97L4wMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/article/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "article-category___en",
    path: "/article/:category()",
    meta: indexH63gD97L4wMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/article/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "article___vi",
    path: "/vi/article",
    meta: indexTXou3chr6IMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: "article___en",
    path: "/article",
    meta: indexTXou3chr6IMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: "index___vi",
    path: "/vi",
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "our-projects-project___vi",
    path: "/vi/our-projects/:project()",
    meta: index8xexFAR3WxMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/our-projects/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: "our-projects-project___en",
    path: "/our-projects/:project()",
    meta: index8xexFAR3WxMeta || {},
    component: () => import("/var/www/html/profile_wowup_nuxt3/pages/our-projects/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubLB9AXdp1nWMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubLB9AXdp1nW
  },
  {
    name: component_45stubLB9AXdp1nWMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubLB9AXdp1nW
  },
  {
    name: component_45stubLB9AXdp1nWMeta?.name,
    path: "/vi-sitemap.xml",
    component: component_45stubLB9AXdp1nW
  },
  {
    name: component_45stubLB9AXdp1nWMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubLB9AXdp1nW
  }
]