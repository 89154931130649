
// @ts-nocheck


export const localeCodes =  [
  "vi",
  "en"
]

export const localeLoaders = {
  "vi": [{ key: "../lang/vi.json", load: () => import("../lang/vi.json" /* webpackChunkName: "locale__var_www_html_profile_wowup_nuxt3_lang_vi_json" */), cache: true }],
  "en": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__var_www_html_profile_wowup_nuxt3_lang_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "vi",
      "name": "Tiếng Việt",
      "files": [
        "/var/www/html/profile_wowup_nuxt3/lang/vi.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "files": [
        "/var/www/html/profile_wowup_nuxt3/lang/en.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "vi",
    "name": "Tiếng Việt",
    "files": [
      {
        "path": "/var/www/html/profile_wowup_nuxt3/lang/vi.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/var/www/html/profile_wowup_nuxt3/lang/en.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
